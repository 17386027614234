import React from 'react';
import { withNavigate } from '../Utilities/Navigation';
import NavSideBar from '../SharedComponents/NavSideBar';
import Footer from '../SharedComponents/Footer';
import Chart from "react-apexcharts";
import axios from 'axios';
import Datepicker from "react-tailwindcss-datepicker";

import { BaseUrlConfig } from '../BaseUrlConfig';

class Charts extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: null,
            value_2: null,

            bar_options: {
                chart: {
                    id: "bar",
                    height: 550
                },
                xaxis: {
                    categories: []
                },
                plotOptions: {
                    bar: {
                        horizontal: false
                    }
                },
                title: {
                    text: 'Data',
                    align: 'left'
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        plotOptions: {
                            bar: {
                                horizontal: false
                            }
                        }
                    }
                }]
            },
            area_options: {
                chart: {
                    type: 'area',
                    height: 550,
                    zoom: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },

                title: {
                    text: 'Data',
                    align: 'left'
                },
                xaxis: {
                    type: 'text',
                },
                yaxis: {
                    opposite: true
                },
                legend: {
                    horizontalAlign: 'left'
                }
            },
            line_options: {
                chart: {
                    height: 550,
                    type: 'line',
                    zoom: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    text: 'Data',
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [],
                    tickAmount: 5,
                    labels: {
                        rotate: 0
                    }
                },
            },
            series: [
                {
                    name: "series-1",
                    data: [30, 40, 45, 50, 49, 60, 70, 91]
                }
            ],

            graph_type: "line",
            loading: false,
            data: [],
            number_of_data: 0,
            data_type_1: "",
            data_type_2: "",
            data_type_3: "",
            data_type_4: "",
            data_type_5: "",
            selected_station: "",
            station_list: [],
        }
    }

    componentDidMount() {
        //check if screen is mobile size
        if (window.innerWidth < 778) {
            this.setState(prevState => ({
                ...prevState,
                line_options: {
                    ...prevState.line_options,
                    xaxis: {
                        ...prevState.line_options.xaxis,
                        tickAmount: 1
                    }
                },
                area_options: {
                    ...prevState.line_options,
                    xaxis: {
                        ...prevState.line_options.xaxis,
                        tickAmount: 1
                    }
                },
                bar_options: {
                    ...prevState.line_options,
                    xaxis: {
                        ...prevState.line_options.xaxis,
                        tickAmount: 1
                    }
                }
            }))
        }

        //todo add event listener when resizing screen
        // window.addEventListener('resize', this.handleWindowSizeChange);
        this.setState({
            loading: true
        })
        // this.fetchData()
        this.fetchDataActual()
        this.fetchDefaultGraphData()
    }

    //todo add event listener when resizing screen
    // handleWindowSizeChange = () => {
    //     if (window.innerWidth < 778) {
    //         if (this.state.tickAmount)
    //     }
    // }

    fetchData = () => {
        var url = "https://jps-hydstra-app.vercel.app/api/testdata"
        try {
            axios.get(url)
                .then(response => {
                    var tmp_array = []
                    var tmp_array_2 = []
                    for (var i = 0; i < 100; i++) {
                        tmp_array.push(response.data[i].col1)
                        tmp_array_2.push(response.data[i].col2)
                    }
                    // response.data.map((row) => {
                    //     tmp_array.push(row.col1)
                    //     tmp_array_2.push(row.col2)
                    // })

                    this.setState(prevState => ({
                        ...prevState,
                        data: response.data,
                        loading: false,
                        line_options: {
                            ...prevState.line_options,
                            xaxis: {
                                ...prevState.line_options.xaxis,
                                categories: tmp_array
                            }
                        },
                        area_options: {
                            ...prevState.line_options,
                            xaxis: {
                                ...prevState.line_options.xaxis,
                                categories: tmp_array
                            }
                        },
                        bar_options: {
                            ...prevState.line_options,
                            xaxis: {
                                ...prevState.line_options.xaxis,
                                categories: tmp_array
                            }
                        },
                        series: [{
                            data: tmp_array_2
                        }]
                    }))
                })

        }
        catch (error) {
            console.log(error)
        }

        // this.setState(prevState => ({
        //     ...prevState,
        //     line_options: {
        //         ...prevState.line_options,
        //         xaxis: {
        //             ...prevState.line_options.xaxis,
        //             categories: 
        //         }
        //     }
        // }))
    }

    fetchDataActual = () => {
        axios.get(BaseUrlConfig() + '/specconnect/get')
            .then(response => {
                this.setState({
                    station_list: response.data
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    fetchDefaultGraphData = () => {
        axios.get(BaseUrlConfig() + '/specconnect/get/default')
            .then(response => {
                var tmp_array = []
                var tmp_array_2 = []
                for (var i = 0; i < response.data[1].length; i++) {
                    tmp_array.push(response.data[1][i].col1)
                    tmp_array_2.push(response.data[1][i].col2)
                }
                // response.data.map((row) => {
                //     tmp_array.push(row.col1)
                //     tmp_array_2.push(row.col2)
                // })

                this.setState(prevState => ({
                    ...prevState,
                    data: response.data[1],
                    loading: false,
                    line_options: {
                        ...prevState.line_options,
                        xaxis: {
                            ...prevState.line_options.xaxis,
                            categories: tmp_array
                        }
                    },
                    area_options: {
                        ...prevState.line_options,
                        xaxis: {
                            ...prevState.line_options.xaxis,
                            categories: tmp_array
                        }
                    },
                    bar_options: {
                        ...prevState.line_options,
                        xaxis: {
                            ...prevState.line_options.xaxis,
                            categories: tmp_array
                        }
                    },
                    series: [{
                        data: tmp_array_2
                    }]
                }))
            })
            .catch(error => {
                console.log(error)
            })
    }

    handleValueChange = (value) => {
        this.setState({
            value: value
        })
    }

    handleValueChange_2 = (value) => {
        this.setState({
            value_2: value
        })
    }

    radioChanged = (event) => {
        this.setState({
            graph_type: event.target.value
        })
    }

    handleDataNumberSelection = (event) => {
        this.setState({
            number_of_data: event.target.value
        })
    }

    handleStationChanged = (event) => {
        this.setState({
            selected_station: event.target.value
        })
    }

    /* spaghetti code for multiple data graph plotting */
    handleDataTypeSelection = () => {
        var select_options = []
        var data_graph_series = []
        for (let i = 0; i < this.state.number_of_data; i++) {
            select_options.push(document.getElementsByName(`select-${i}`)[0].value)
        }

        for (let i = 0; i < select_options.length; i++) {
            var tmp_array = []

            //Test data
            // if (select_options[i] === "TMPA") {
            //     for (let count = 0; count < 100; count++) {
            //         tmp_array.push(this.state.data[count].col2)
            //     }
            // }
            // else if (select_options[i] === "VWCB") {
            //     for (let count = 0; count < 100; count++) {
            //         tmp_array.push(this.state.data[count].col3)
            //     }
            // }

            // else if (select_options[i] === "WET") {
            //     for (let count = 0; count < 100; count++) {
            //         tmp_array.push(this.state.data[count].col4)
            //     }
            // }

            // else if (select_options[i] === "PAR") {
            //     for (let count = 0; count < 100; count++) {
            //         tmp_array.push(this.state.data[count].col5)
            //     }
            // }

            // else if (select_options[i] === "SRD") {
            //     for (let count = 0; count < 100; count++) {
            //         tmp_array.push(this.state.data[count].col6)
            //     }
            // }

            // else if (select_options[i] === "HMD") {
            //     for (let count = 0; count < 100; count++) {
            //         tmp_array.push(this.state.data[count].col7)
            //     }
            // }

            // else if (select_options[i] === "TMP") {
            //     for (let count = 0; count < 100; count++) {
            //         tmp_array.push(this.state.data[count].col8)
            //     }
            // }

            // else if (select_options[i] === "RNF") {
            //     for (let count = 0; count < 100; count++) {
            //         tmp_array.push(this.state.data[count].col9)
            //     }
            // }

            // else if (select_options[i] === "WND") {
            //     for (let count = 0; count < 100; count++) {
            //         tmp_array.push(this.state.data[count].col10)
            //     }
            // }

            // else if (select_options[i] === "WNG") {
            //     for (let count = 0; count < 100; count++) {
            //         tmp_array.push(this.state.data[count].col11)
            //     }
            // }

            // else if (select_options[i] === "WNS") {
            //     for (let count = 0; count < 100; count++) {
            //         tmp_array.push(this.state.data[count].col12)
            //     }
            // }

            // else if (select_options[i] === "BTL") {
            //     for (let count = 0; count < 100; count++) {
            //         tmp_array.push(this.state.data[count].col13)
            //     }
            // }

            // else if (select_options[i] === "DEW") {
            //     for (let count = 0; count < 100; count++) {
            //         tmp_array.push(this.state.data[count].col14)
            //     }
            // }

            // else if (select_options[i] === "ECBC") {
            //     for (let count = 0; count < 100; count++) {
            //         tmp_array.push(this.state.data[count].col15)
            //     }
            // }

            // else if (select_options[i] === "WWCD") {
            //     for (let count = 0; count < 100; count++) {
            //         tmp_array.push(this.state.data[count].col16)
            //     }
            // }

            //Mock up specconnect data
            if (select_options[i] === "Rainfall") {
                for (let count = 0; count < this.state.data.length; count++) {
                    tmp_array.push(this.state.data[count].col2)
                }
            }
            else if (select_options[i] === "Accumulated Rainfall") {
                for (let count = 0; count < this.state.data.length; count++) {
                    tmp_array.push(this.state.data[count].col3)
                }
            }
            else if (select_options[i] === "Temperature") {
                for (let count = 0; count < this.state.data.length; count++) {
                    tmp_array.push(this.state.data[count].col4)
                }
            }
            else if (select_options[i] === "Relative Humidity") {
                for (let count = 0; count < this.state.data.length; count++) {
                    tmp_array.push(this.state.data[count].col5)
                }
            }

            var tmp_data = {
                name: select_options[i],
                data: tmp_array
            }

            data_graph_series.push(tmp_data)
        }

        this.setState({
            series: data_graph_series
        })
    }

    submitHandler = () => {
        if (this.state.value === null || this.state.value_2 === null) {
            console.log("Cannot be null")
        }
        else if (this.state.selected_station === "") {
            console.log("Select a station")
        }
        else {
            this.setState({
                loading: true
            })
            axios.post(BaseUrlConfig() + '/specconnect/getRangeData',
                {
                    "serial_number": this.state.selected_station,
                    "start_date": this.state.value["startDate"],
                    "end_date": this.state.value_2["startDate"]
                })
                .then(response => {
                    var tmp_array = []
                    var tmp_array_2 = []
                    for (var i = 0; i < response.data[1].length; i++) {
                        tmp_array.push(response.data[1][i].col1)
                        tmp_array_2.push(response.data[1][i].col2)
                    }
                    // response.data.map((row) => {
                    //     tmp_array.push(row.col1)
                    //     tmp_array_2.push(row.col2)
                    // })

                    this.setState(prevState => ({
                        ...prevState,
                        data: response.data[1],
                        loading: false,
                        line_options: {
                            ...prevState.line_options,
                            xaxis: {
                                ...prevState.line_options.xaxis,
                                categories: tmp_array
                            }
                        },
                        area_options: {
                            ...prevState.line_options,
                            xaxis: {
                                ...prevState.line_options.xaxis,
                                categories: tmp_array
                            }
                        },
                        bar_options: {
                            ...prevState.line_options,
                            xaxis: {
                                ...prevState.line_options.xaxis,
                                categories: tmp_array
                            }
                        },
                        series: [{
                            data: tmp_array_2
                        }]
                    }))
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    renderSelect = (value) => {
        const selects = []
        if (value === 0) {
            return (
                <span>Sila pilih bilangan data...</span>
            )
        }
        else {
            for (let i = 0; i < value; i++) {
                selects.push(
                    <select className="select select-sm select-bordered w-full max-w-xs"
                        name={"select-" + i}
                        onChange={this.handleDataTypeSelection}>
                        <option disabled selected value="default">Data Type</option>

                        {/* Test Data */}
                        {/* <option value="TMPA">TMPA</option>
                        <option value="VWCB">VWCB</option>
                        <option value="WET">WET</option>
                        <option value="PAR">PAR</option>
                        <option value="SRD">SRD</option>
                        <option value="HMD">HMD</option>
                        <option value="TMP">TMP</option>
                        <option value="RNF">RNF</option>
                        <option value="WND">WND</option>
                        <option value="WNG">WNG</option>
                        <option value="WNS">WNS</option>
                        <option value="BTL">BTL</option>
                        <option value="DEW">DEW</option>
                        <option value="ECBC">ECBC</option>
                        <option value="VWCD">VWCD</option>
                        <option value="TMPE">TMPE</option> */}

                        {/* Mock up specconnect data */}
                        <option value="Rainfall">Hujan</option>
                        <option value="Accumulated Rainfall">Hujan Terkumpul</option>
                        <option value="Temperature">Suhu</option>
                        <option value="Relative Humidity">Kelembapan Relatif</option>
                    </select>
                )
            }
            return selects
        }
    }

    render() {
        const loadingContent = (
            <React.Fragment>
                <div className="flex justify-center pt-10">
                    <span className="loading loading-ring loading-lg text-success w-2/5"></span>

                </div>
                <br />

                <div className="flex justify-center">
                    <a href="#!" class="mb-4 text-base font-extrabold text-gray-900 md:text-base lg:text-xl">
                        <span class="text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-yellow-500
                            animate-pulse">
                            Sila tunggu, data sedang dimuatkan...</span>
                    </a>
                </div>


            </React.Fragment>
        )

        const mainContent = (
            <React.Fragment>
                <div className="collapse collapse-arrow bg-green-100">
                    <input type="checkbox" className="peer" />
                    <div className="collapse-title bg-green-100">
                        Klik di sini untuk melihat Konfigurasi & Penapis Graf
                    </div>
                    <div className="collapse-content bg-green-100 peer-checked:bg-green-200">
                        <div>
                            <div className="text-left italic p-4">
                                Note: For optimal experience, graph plotting works best with desktop devices. If you are having display issue in mobile, please consider to switch to desktop mode.
                            </div>
                        </div>
                        <div className="font-bold pt-6">
                            <div className="text-left text-2xl underline underline-offset-8 p-4">
                                Konfigurasi Graf
                            </div>

                            <div className="text-left underline underline-offset-8 p-4">
                                Sila pilih jenis graf:
                            </div>

                            <div className="grid grid-cols-2 lg:grid-cols-6 gap-4">
                                <div className="flex items-center justify-center col-span-2">
                                    <span className="pr-8">Carta Garis</span>
                                    <input type="radio" name="radio-2" className="radio radio-primary"
                                        defaultChecked
                                        value="line" onChange={(e) => this.radioChanged(e)} />
                                </div>
                                <div className="flex items-center justify-center col-span-2">
                                    <span className="pr-8">Carta Kawasan</span>
                                    <input type="radio" name="radio-2" className="radio radio-primary"
                                        value="area" onChange={(e) => this.radioChanged(e)} />
                                </div>
                                <div className="flex items-center justify-center col-span-2">
                                    <span className="pr-8">Carta Bar</span>
                                    <input type="radio" name="radio-2" className="radio radio-primary"
                                        value="bar" onChange={(e) => this.radioChanged(e)} />
                                </div>
                            </div>

                            <div className="text-left underline underline-offset-8 p-4">
                                Sila pilih bilangan data untuk plot graf:
                            </div>

                            <div className="flex items-center justify-center">
                                <select className="select select-sm select-bordered w-full max-w-xs"
                                    onChange={this.handleDataNumberSelection}>
                                    <option disabled selected>Bilangan Data</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>

                            <div className="text-left underline underline-offset-8 p-4">
                                Sila pilih data yang ingin digunakan untuk plot graf:
                            </div>

                            <div className="flex items-center justify-center gap-4">
                                {this.renderSelect(this.state.number_of_data)}
                            </div>
                        </div>

                        <div className="font-bold pt-6">
                            <div className="text-left text-2xl underline underline-offset-8 p-4">
                                Filters
                            </div>

                            <div className="text-left italic p-4">
                                Filter tip: For optimal performance, please search for data with precise date range. Although the system is capable of querying large amount of data at once, it may lead to increase load times and slower performance.
                                Please click on the search button to query data in this section.
                            </div>

                            <div className="text-left underline underline-offset-8 p-4">
                                Sila pilih stesen:
                            </div>

                            <div className="flex items-center justify-center">
                                <select className="select select-sm select-bordered w-full max-w-xs"
                                    onChange={this.handleStationChanged}>
                                    <option disabled selected>Nama Stesen</option>
                                    {this.state.station_list.map((data) => (
                                        <option key={data.serial_number} value={data.serial_number}>{data.station_name}</option>
                                    ))}
                                </select>
                            </div>

                            <br />

                            <div className="flex -mb-16 px-6 gap-6">
                                <span className="w-2/4">Tarikh Mula</span>

                                <span className="w-2/4">Tarikh Tamat</span>
                            </div>

                            <div className="flex items-center justify-center h-48 px-6 gap-6">
                                <Datepicker
                                    useRange={false}
                                    asSingle={true}
                                    value={this.state.value}
                                    onChange={this.handleValueChange}
                                    popoverDirection="up"
                                />

                                <Datepicker
                                    useRange={false}
                                    asSingle={true}
                                    value={this.state.value_2}
                                    onChange={this.handleValueChange_2}
                                    popoverDirection="up"
                                />
                            </div>

                            <div className="flex items-center justify-center h-16 gap-8">
                                <button className="btn btn-info w-32"
                                    onClick={this.submitHandler}>Cari</button>
                                <button className="btn btn-active btn-secondary w-32">Semula</button>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.graph_type === "bar" ?
                    <div className="mixed-chart p-0 lg:p-10 w-screen lg:w-full">
                        <Chart
                            options={this.state.bar_options}
                            series={this.state.series}
                            type="bar"
                            height={550}

                        />
                    </div>
                    : this.state.graph_type === "area" ?
                        <div className="mixed-chart p-0 lg:p-10 w-screen lg:w-full">
                            <Chart
                                options={this.state.area_options}
                                series={this.state.series}
                                type="area"
                                height={600}
                            />
                        </div>
                        :
                        <div className="mixed-chart p-0 lg:p-10 w-screen lg:w-full">
                            <Chart
                                options={this.state.line_options}
                                series={this.state.series}
                                type="line"
                                height={650}
                            />
                        </div>
                }
            </React.Fragment>
        )

        return (
            <React.Fragment>
                {this.state.loading ?
                    <NavSideBar pageContent={loadingContent} />
                    :
                    <NavSideBar pageContent={mainContent} />
                }

                <Footer />
            </React.Fragment>
        )
    }
}

export default withNavigate(Charts);