import React from 'react';
import axios from 'axios';
import { withNavigate } from '../Utilities/Navigation';
import NavSideBar from '../SharedComponents/NavSideBar';
import Footer from '../SharedComponents/Footer';

import { BaseUrlConfig } from '../BaseUrlConfig';

class SirenLog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            data: [],
            currentPage: 1,
            totalDataCount: 0,
            pageSize: 50 // Number of items per page
        }
    }

    componentDidMount() {
        this.setState({
            loading: true
        })
        this.fetchData()
    }

    componentDidUpdate(prevProps, prevState) {
        // Fetch data when currentPage changes
        if (prevState.currentPage !== this.state.currentPage) {
            this.fetchData();
        }
    }

    fetchData = () => {
        const { currentPage, pageSize } = this.state;
        this.setState({
            loading: true
        });
        axios.post(`${BaseUrlConfig()}/specconnect/get/sirenlog`,
            {
                "page": currentPage,
                "page_size": pageSize
            })
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    totalDataCount: response.data["data_count"],
                    loading: false
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false
                });
            });
    }

    goToPage = (pageNumber) => {
        this.setState({
            currentPage: pageNumber
        });
    }

    renderRows = () => {
        let rows = [];

        if (this.state.loading === true) {
            rows.push(
                <tr className="hover" key="loading">
                    <td colSpan={6} style={{ textAlign: "center" }}>
                        <span className="loading loading-bars text-success loading-lg"></span>
                        <br />
                        <a href="#!" className="mb-4 text-base font-extrabold text-gray-900 md:text-base lg:text-xl">
                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-yellow-500
                        animate-pulse">
                                Sila tunggu, data sedang dimuatkan...</span>
                        </a>
                    </td>
                </tr>
            );
        } else {
            if (this.state.data.length === 0) {
                rows.push(
                    <tr className="hover" key="no-data">
                        <td colSpan={5} style={{ textAlign: "center" }}>Tiada Data.</td>
                    </tr>
                );
            } else {
                this.state.data.forEach((row, idx) => {
                    rows.push(
                        <tr className="hover" key={row["log_id"]}>
                            <th>{idx + 1}</th>
                            <td style={{ width: "30%" }}>{row["station_name"]}</td>
                            <td style={{ width: "20%" }}>{row["status"]}</td>
                            <td style={{ width: "20%" }}>{row["trigger_mode"]}</td>
                            <td style={{ width: "30%" }}>{row["trigger_datetime"]}</td>
                        </tr>
                    );
                });
            }
        }
        return rows;
    }

    renderPagination = () => {
        const { currentPage, totalDataCount, pageSize } = this.state;
        const totalPages = Math.ceil(totalDataCount / pageSize);

        if (totalPages <= 1) {
            return null; // No pagination needed if there's only one page
        }

        const pages = [];
        const pageToShow = 1; // Number of pages to show in pagination

        // Determine start and end page numbers
        let startPage = Math.max(1, currentPage - Math.floor(pageToShow / 2));
        let endPage = Math.min(totalPages, startPage + pageToShow - 1);

        // Adjust startPage and endPage to ensure we always show pageToShow number of pages
        if (endPage - startPage + 1 < pageToShow) {
            startPage = Math.max(1, endPage - pageToShow + 1);
        }

        // Add previous arrow
        if (currentPage > 1) {
            pages.push(
                <button
                    key="prev"
                    onClick={() => this.goToPage(currentPage - 1)}
                    className="btn btn-outline lg:mr-2"
                >
                    &laquo; Previous
                </button>
            );
        }

        // Add first page if not in the visible range
        if (startPage > 1) {
            pages.push(
                <button
                    key={1}
                    onClick={() => this.goToPage(1)}
                    className={`btn ${currentPage === 1 ? 'btn-primary' : 'btn-outline'} lg:mr-2`}
                >
                    {1}
                </button>
            );
            if (startPage > 2) {
                pages.push(
                    <span key="ellipsis1" className="pagination-ellipsis mx-2">
                        ...
                    </span>
                );
            }
        }

        // Render the page numbers
        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={i}
                    onClick={() => this.goToPage(i)}
                    className={`btn ${currentPage === i ? 'btn-primary' : 'btn-outline'} lg:mr-2`}
                >
                    {i}
                </button>
            );
        }

        // Add last page if not in the visible range
        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pages.push(
                    <span key="ellipsis2" className="pagination-ellipsis mx-2">
                        ...
                    </span>
                );
            }
            pages.push(
                <button
                    key={totalPages}
                    onClick={() => this.goToPage(totalPages)}
                    className={`btn ${currentPage === totalPages ? 'btn-primary' : 'btn-outline'} lg:mr-2`}
                >
                    {totalPages}
                </button>
            );
        }

        // Add next arrow
        if (currentPage < totalPages) {
            pages.push(
                <button
                    key="next"
                    onClick={() => this.goToPage(currentPage + 1)}
                    className="btn btn-outline"
                >
                    Next &raquo;
                </button>
            );
        }

        return (
            <div className="mt-4 flex justify-center">
                {pages}
            </div>
        );
    }

    render() {
        const mainContent = (
            <React.Fragment>
                <div className="flex justify-center pt-5">
                    <h1 className="font-bold text-5xl">Senarai Log Siren</h1>
                </div>

                <div className="overflow-x-auto p-10 w-screen lg:w-full">
                    <table className="table border border-gray-300">
                        {/* head */}
                        <thead className="text-lg">
                            <tr>
                                <th></th>
                                <th>Nama Stesen</th>
                                <th>Status</th>
                                <th>Mod</th>
                                <th>Tarikh / Masa</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderRows()}
                        </tbody>
                    </table>
                </div>

                {this.renderPagination()}
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <NavSideBar pageContent={mainContent} />
                <Footer />
            </React.Fragment>
        );
    }
}

export default withNavigate(SirenLog);
