import React from 'react';
import axios from 'axios'
import { withNavigate } from '../Utilities/Navigation';
import NavSideBar from '../SharedComponents/NavSideBar';
import Footer from '../SharedComponents/Footer';

import { BaseUrlConfig } from '../BaseUrlConfig'

class StationData extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            // data: [
            //     {
            //         "name": "Lata Payung (Setiu)",
            //         "status": "Active",
            //         "threshold": 10
            //     },
            //     {
            //         "name": "Chemerong (Dungun)",
            //         "status": "Active",
            //         "threshold": 30
            //     },
            //     {
            //         "name": "Air Menderu (Kemaman)",
            //         "status": "Active",
            //         "threshold": 50
            //     }
            // ],

            loading: false,
            data: [],
            station_name: "",
            station_status: "",
            threshold: "",
            manual_flag: "",
            station_image: "",
            station_file_name: "",
            station_file_object: "",

            current_station: ""
        }
    }

    componentDidMount() {
        this.setState({
            loading: true
        })

        this.fetchData()
    }

    init = () => {
        window.location.reload()
    }

    fetchData = () => {
        axios.get(BaseUrlConfig() + '/station/get')
            .then(response => {
                this.setState({
                    data: response.data,
                    loading: false
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    renderRows = () => {
        let rows = []

        if (this.state.loading === true) {
            rows.push(
                <tr className="hover">
                    <td colSpan={6} style={{ textAlign: "center" }}>
                        <span className="loading loading-bars text-success loading-lg"></span>
                        <br />
                        <a href="#!" class="mb-4 text-base font-extrabold text-gray-900 md:text-base lg:text-xl">
                            <span class="text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-yellow-500
                        animate-pulse">
                                Sila tunggu, data sedang dimuatkan...</span>
                        </a>
                    </td>
                </tr>
            )
        }

        else {
            if (this.state.data.length === 0) {
                rows.push(
                    <tr className="hover">
                        <td colSpan={6} style={{ textAlign: "center" }}>Tiada Data.</td>
                    </tr>
                )
            }

            else {
                // this.state.data.forEach((row, idx) => {
                //     rows.push(
                //         <tr className="hover">
                //             <th>{idx + 1}</th>
                //             <td style={{ width: "30%" }}>{row["name"]}</td>
                //             <td style={{ width: "20%" }}>{row["status"]}</td>
                //             <td style={{ width: "20%" }}>{row["threshold"]}</td>
                //             <td style={{ width: "30%" }} className="flex gap-4">
                //                 <button className="btn btn-primary w-24" onClick={() => this.openEditFunction(idx)}>Edit</button>
                //                 <button className="btn btn-secondary w-24" onClick={() => this.deleteStation(idx)}>Delete</button>
                //             </td>
                //         </tr>
                //     )
                // })

                this.state.data.forEach((row, idx) => {
                    rows.push(
                        <tr className="hover" key={row["station_id"]}>
                            <th>{idx + 1}</th>
                            <td style={{ width: "20%" }}>{row["station_name"]}</td>
                            <td style={{ width: "20%" }}>{row["station_status"]}</td>
                            <td style={{ width: "20%" }}>{row["threshold"]}</td>
                            <td style={{ width: "10%" }}>
                                {/* {
                                row["manual_flag"] === "Y" ?
                                    "Active" :
                                    "Inactive"
                            } */}
                                <div className="flex items-center justify-center col-span-2">
                                    <input type="checkbox" className="toggle toggle-error" checked={row["manual_flag"]} onChange={() => this.manualSwitch(row["idx"], row["station_id"])} />
                                </div>
                            </td>
                            <td style={{ width: "30%" }} className="flex gap-4">
                                <button className="btn btn-primary w-24" onClick={() => this.openEditFunctionActual(idx, row["station_id"])}>Kemaskini Data</button>
                                <button className="btn btn-primary w-24" onClick={() => this.openEditImageFunctionActual(idx, row["station_id"])}>Kemaskini Gambar</button>
                                <button className="btn btn-secondary w-24" onClick={() => this.deleteStationActual(row["station_id"])}>Padam</button>
                            </td>
                        </tr>
                    )
                })
            }
        }
        return rows
    }

    handleStationNameChanged = (event) => {
        this.setState({
            station_name: event.target.value
        })
    }

    handleThresholdChanged = (event) => {
        this.setState({
            threshold: event.target.value
        })
    }

    handleManualFlagChanged = (event) => {
        this.setState({
            manual_flag: event.target.value
        })
    }

    uploadHandler = (event) => {
        let file = event.currentTarget.files[0]
        console.log(file.name)
        console.log(file)

        this.setState({
            station_file_name: file.name,
            station_file_object: file
        })
    }

    addStation = () => {
        console.log(this.state.station_file_name)
        console.log(this.state.station_file_object)
        this.setState({
            data: [...this.state.data,
            {
                "name": this.state.station_name,
                "status": "Active",
                "threshold": this.state.threshold
            }
            ],
            station_name: "",
            threshold: ""
        })
    }

    addStationActual = () => {
        axios.post(BaseUrlConfig() + '/station/add', {
            "station_name": this.state.station_name,
            "station_status": this.state.station_status,
            "threshold": this.state.threshold
        })
            .then(response => {
                if (response.data[0] === "OK") {
                    this.addStationImage(response.data[1])
                }
                else if (response.data === "Failed") {
                    console.log("Station Name already exists.")
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    addStationImage = (station_id) => {
        const data = new FormData()

        data.append('file_object', this.state.station_file_object)
        data.append('file_name', this.state.station_file_name)

        data.append('station_id', station_id)

        axios.post(BaseUrlConfig() + '/station/add/file', data)
            .then(response => {
                if (response.data === "OK") {
                    this.init()
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    openEditFunction = (idx) => {
        this.setState({
            current_station: idx,
            station_name: this.state.data[idx]["name"],
            threshold: this.state.data[idx]["threshold"],
            manual_flag: this.state.data[idx]["manual_flag"],
        }, () => {
            window.edit_station_modal.showModal()
        })
    }

    editStation = () => {
        let stations = [...this.state.data]
        stations[this.state.current_station] = {
            "name": this.state.station_name,
            "status": "Active",
            "threshold": this.state.threshold,
            "manual_flag": this.state.manual_flag
        }
        this.setState({
            data: stations,
            station_name: "",
            threshold: "",
            manual_flag: "",
            current_station: ""
        })
    }

    openEditFunctionActual = (idx, station_id) => {
        this.setState({
            current_station: station_id,
            station_name: this.state.data[idx]["station_name"],
            station_status: this.state.data[idx]["station_status"],
            threshold: this.state.data[idx]["threshold"],
            manual_flag: this.state.data[idx]["manual_flag"],
        }, () => {
            window.edit_station_modal.showModal()
        })
    }

    editStationActual = () => {
        axios.post(BaseUrlConfig() + '/station/update', {
            "station_id": this.state.current_station,
            "station_name": this.state.station_name,
            "station_status": this.state.station_status,
            "threshold": this.state.threshold,
        })
            .then(response => {
                if (response.data === "OK") {
                    this.init()
                }
                else if (response.data === "Failed") {
                    console.log("Station Name already exists.")
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    openEditImageFunctionActual = (idx, station_id) => {
        this.setState({
            current_station: station_id,
            station_name: this.state.data[idx]["station_name"],
            station_image: this.state.data[idx]["station_image_directory"]
        }, () => {
            window.edit_station_image_modal.showModal()
        })
    }

    editStationImageActual = () => {
        const data = new FormData()

        data.append('file_object', this.state.station_file_object)
        data.append('file_name', this.state.station_file_name)

        data.append('station_id', this.state.current_station)

        axios.post(BaseUrlConfig() + '/station/update/image', data)
            .then(response => {
                if (response.data === "OK") {
                    this.init()
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    manualSwitch = (id, station_id) => {
        this.setState({ loading: true })
        let station_data = [...this.state.data]
        console.log(station_data[0]["manual_flag"])
        station_data[id]["manual_flag"] = !station_data[id]["manual_flag"]

        axios.post(BaseUrlConfig() + "/station/update/manualflag", {
            "station_id": station_id,
            "checked": station_data[id]["manual_flag"]
        })
            .then(response => {
                if (response.data === "OK") {
                    this.fetchData()
                }
            })
            .catch(error => {
                this.setState({
                    loading: false
                })
                console.log(error)
            })
    }

    deleteStation = (idx) => {
        let stations = this.state.data
        stations.splice(idx, 1)
        this.setState({ stations })
    }

    deleteStationActual = (station_id) => {
        axios.post(BaseUrlConfig() + '/station/delete', {
            "station_id": station_id
        })
            .then(response => {
                if (response.data === "OK") {
                    this.init()
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        const mainContent = (
            <React.Fragment>
                {/* <div>
                    <h1>**Data here is only for testing purposes**</h1>
                </div> */}

                <div className="flex justify-center pt-5">
                    <h1 className="font-bold text-5xl">Senarai Stesen</h1>
                </div>

                <div className="overflow-x-auto p-10 w-screen lg:w-full">
                    <table className="table border border-gray-300">
                        {/* head */}
                        <thead className="text-lg">
                            <tr>
                                <th></th>
                                <th>Nama Stesen</th>
                                <th>Siren Stesen</th>
                                <th>Had Stesen</th>
                                <th>Suis Manual</th>
                                <th>Tindakan</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderRows()}
                        </tbody>
                    </table>
                </div>

                <div className="flex justify-center">
                    <button className="btn btn-accent w-48" onClick={() => {
                        this.setState({
                            station_name: "",
                            threshold: ""
                        })
                        window.my_modal_2.showModal()
                    }}>Tambah Stesen</button>
                </div>

                {/* Add station dialog */}
                <dialog id="my_modal_2" className="modal">
                    <form method="dialog" className="modal-box">
                        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                        <h3 className="font-bold text-lg">Tambah Stesen</h3>
                        <br />
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Nama Stesen</span>
                            </label>
                            <input type="text" placeholder="Sila taip nama stesen di sini" className="input input-bordered"
                                value={this.state.station_name}
                                onChange={this.handleStationNameChanged} />
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Had Stesen</span>
                            </label>
                            <input type="number" placeholder="Sila taip had stesen di sini" className="input input-bordered"
                                value={this.state.threshold}
                                onChange={this.handleThresholdChanged} />
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Gambar Stesen</span>
                            </label>
                            <input type="file" accept="image/*"
                                onChange={this.uploadHandler}
                                className="file-input file-input-bordered file-input-accent w-full max-w-xs" />
                        </div>
                        <br />
                        <div className="flex justify-center">
                            {/* <button className="btn btn-primary w-48" onClick={this.addStation}>Add Station</button> */}
                            <button className="btn btn-primary w-48" onClick={this.addStationActual}>Tambah</button>
                        </div>
                    </form>
                    <form method="dialog" className="modal-backdrop">
                        <button>close</button>
                    </form>
                </dialog>

                {/* Edit station dialog */}
                <dialog id="edit_station_modal" className="modal">
                    <form method="dialog" className="modal-box">
                        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                        <h3 className="font-bold text-lg">Kemaskini Stesen</h3>
                        <br />
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Nama Stesen</span>
                            </label>
                            <input type="text" placeholder="Sila taip nama stesen di sini" className="input input-bordered"
                                value={this.state.station_name}
                                onChange={this.handleStationNameChanged} />
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Had Stesen</span>
                            </label>
                            <input type="number" placeholder="Sila taip had stesen di sini" className="input input-bordered"
                                value={this.state.threshold}
                                onChange={this.handleThresholdChanged} />
                        </div>
                        <br />
                        <div className="flex justify-center">
                            {/* <button className="btn btn-primary w-48" onClick={this.editStation}>Edit Station</button> */}
                            <button className="btn btn-primary w-48" onClick={this.editStationActual}>Kemaskini</button>
                        </div>
                    </form>
                    <form method="dialog" className="modal-backdrop">
                        <button>close</button>
                    </form>
                </dialog>

                {/* Edit station image dialog */}
                <dialog id="edit_station_image_modal" className="modal">
                    <form method="dialog" className="modal-box">
                        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                        <h3 className="font-bold text-lg">Kemaskini Gambar Stesen</h3>
                        <br />
                        <div className="flex items-center justify-center col-span-2">
                            <img src={BaseUrlConfig() + "/static/resources/" + this.state.station_image} className="rounded-lg" alt="#"
                                style={{ fill: 'red' }} />
                        </div>
                        <br />
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Nama Stesen</span>
                            </label>
                            <input type="text" disabled placeholder="Sila taip nama stesen di sini" className="input input-bordered"
                                value={this.state.station_name}
                                onChange={this.handleStationNameChanged} />
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Gambar Stesen</span>
                            </label>
                            <input type="file" accept="image/*"
                                onChange={this.uploadHandler}
                                className="file-input file-input-bordered file-input-accent w-full max-w-xs" />
                        </div>

                        <br />
                        <div className="flex justify-center">
                            {/* <button className="btn btn-primary w-48" onClick={this.editStation}>Edit Station</button> */}
                            <button className="btn btn-primary w-48" onClick={this.editStationImageActual}>Kemaskini</button>
                        </div>
                    </form>
                    <form method="dialog" className="modal-backdrop">
                        <button>close</button>
                    </form>
                </dialog>

                <br />
            </React.Fragment>
        )
        return (
            <React.Fragment>
                <NavSideBar pageContent={mainContent} />

                <Footer />
            </React.Fragment>

        )
    }
}

export default withNavigate(StationData);